
<template>
  <div class="flex flex-col items-center">
    <span class="font-bold text-xl">Ingresar plataforma Encarga</span>
    <div class="py-3">
      <InputText type="text" v-model="dataSesionEncarga.user" class="w-full mb-3 p-inputtext-sm" placeholder="usuario"/>
      <Password v-model="dataSesionEncarga.password" toggleMask :feedback="false" class="p-inputtext-sm w-full"
                inputClass="w-full" autocomplete="new-password" placeholder="contraseña"/>
    </div>
    <Button @click="loginInEncarga">iniciar sesión</Button>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import Password from 'primevue/password'
import Swal from 'sweetalert2'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'LoginEncarga',
  components: { Password },
  setup () {
    const store = useStore()
    const tokenEncarga = computed(() => store.getters['controlEnvioStorage/getTokenEncarga'])
    const dataSesionEncarga = ref({
      user: '',
      password: ''
    })
    const loginInEncarga = () => {
      if (dataSesionEncarga.value.user === '' || dataSesionEncarga.value.password === '') {
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'El usuario y contraseña es requerido',
          timerProgressBar: true,
          showConfirmButton: false,
          timer: 3000
        })
        return
      }
      const params = {
        user: dataSesionEncarga.value.user,
        pass: dataSesionEncarga.value.password
      }
      store.dispatch('controlEnvioStorage/loginIntoEncarga', params).then(async () => {
        dataSesionEncarga.value = {
          user: '',
          password: ''
        }
        if (tokenEncarga.value) {
          await store.dispatch('controlEnvioStorage/loadInfoEncarga')
          await Swal.fire({
            icon: 'success',
            title: 'Proceso completado con éxito',
            text: 'Se ha iniciado sesión con éxito',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true
          })
        }
      })
    }
    return {
      loginInEncarga,
      dataSesionEncarga
    }
  }
})
</script>

<style scoped>

</style>
